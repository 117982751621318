<template>
  <div class="ali-apply">
    <div class="item">
      <div class="item-title">
        <p>签约支付宝</p>
      </div>

      <el-form ref="form" :model="info" :rules="rules" label-width="160px" class="item-form" size="small">
        <el-form-item label="签约信息">
          <el-button type="text" @click="handleSign">点击前往签约</el-button>
        </el-form-item>
      </el-form>

    </div>

    <div class="item">
      <div class="item-title">
        <p>系统授权</p>
      </div>
      <el-form ref="form" :model="info" :rules="rules" label-width="160px" class="item-form" size="small">
        <el-form-item label="支付名称" prop="name">
          <el-input v-model="info.name" placeholder="请输入支付名称" style="width: 240px" @change="handleOnChange"></el-input>
        </el-form-item>
        <el-form-item label="支付授权">
          <div>
            <qrcode :value="accessTokenUrl" :options="{ width: 200 }"></qrcode>
            <p style="margin-left: 16px">请用【支付宝】扫描上方二维码完成资质认证后点击下方【完成】
              <br/>
              <span style="color: red">(若已经签约,请勿重复签约；重复签约会导致已有授权失效)</span></p>
          </div>
        </el-form-item>
      </el-form>

    </div>

  </div>
</template>

<script>
  import Upload from '@/components/Upload'

  export default {
    name: '',
    components: {
      Upload
    },
    props: {
      accessTokenUrl: {
        type: String,
        default: ''
      },
      uuid: {
        type: String,
        default: ''
      },
      name: {
        type: String,
        default: ''
      }
    },
    mounted: function () {
      this.info.uuid = this.uuid
    },
    data: function () {
      return {
        checked: false,
        info: {
          name: '',
          uuid:''
        },
        rules: {
          name: [
            {required: true, message: '请输入公司', trigger: 'blur'},
            {min: 3, message: '长度不少于3个字符', trigger: 'blur'}
          ],
        },
      }
    },
    methods: {
      handleSign() {
        window.open("https://openhome.alipay.com/isv/settling/inviteSign.htm?appId=2021001163699308&sign=yWGlJ0BPk8OBmA7UWDCQirNCpE3HXjau8ui9DEoAd4E=", "_blank");
      },
      handleOnChange(val){
        this.$emit('update:name', val)
      },
      fileUploadSuccess(data) {

      }
    },
  }
</script>

<style lang="scss">
  .ali-apply {
    margin-top: $margin;

    .item {
      .item-title {
        margin-bottom: 20px;

        p {
          padding: 6px;
          background-color: #fff;
          border-bottom: 1px solid #e9eaec;
          font-size: 18px;
        }
      }

      .item-form {
        margin: 0 auto;
        width: 66.66666667%;

        .example {
          text-align: center;

          img {
            max-width: 100px;
          }
        }

        .explain {
          font-size: 12px;
          color: #999;
        }
      }


    }


  }
</style>
