import request from '@/util/request'

export const payAliCreate = (data) => {
  return request({
    url: '/api/v1/collection/pay/ali/create',
    method: 'post',
    data
  })
}

export const payAliUnion = (params) => {
  return request({
    url: '/api/v1/collection/ali/union',
    method: 'get',
    params
  })
}

export const payAliList = (params) => {
  return request({
    url: '/api/v1/collection/ali/list',
    method: 'get',
    params
  })
}

export const payAliUpdate = (params) => {
  return request({
    url: '/api/v1/collection/ali/audit/update',
    method: 'get',
    params
  })
}

export const payAliDelete = (params) => {
  return request({
    url: '/api/v1/collection/ali/delete',
    method: 'get',
    params
  })
}

export const payAliTest = (params) => {
  return request({
    url: '/api/v1/collection/ali/payTest',
    method: 'get',
    params
  })
}

export const payWxApply = (data) => {
  return request({
    url: '/api/v1/collection/wx/apply',
    method: 'post',
    data
  })
}

export const payWxUnion = (params) => {
  return request({
    url: '/api/v1/collection/wx/union',
    method: 'get',
    params
  })
}

export const payWxList = (data) => {
  return request({
    url: '/api/v1/collection/wx/list',
    method: 'get',
    data
  })
}

export const payBindDevice = (data) => {
  return request({
    url: '/api/v1/collection/device/bind/pay',
    method: 'post',
    data
  })
}

export const quickPayBindDrCoffee = (data) => {
  return request({
    url: '/api/v1/collection/quick/pay/bindDrCoffee',
    method: 'post',
    data
  })
}

// export const payDeviceUnion = (params) => {
//   return request({
//     url: '/api/v1/marketing/pay/device/union',
//     method: 'get',
//     params
//   })
// }
//
// export const payDeviceList = (params) => {
//   return request({
//     url: '/api/v1/marketing/pay/device/list',
//     method: 'get',
//     params
//   })
// }
//
//
// export const payWxRefund = (data) => {
//   return request({
//     url: "/api/v1/marketing/pay/wx/refund",
//     method: 'post',
//     data
//   })
// }
//
// export const payAliRefund = (data) => {
//   return request({
//     url: "/api/v1/marketing/pay/ali/refund",
//     method: 'post',
//     data
//   })
// }
