<template>
  <div class="pay-ali-config">
    <router-back></router-back>

    <div class="pay-ali-config-container">
      <el-steps :active="step" finish-status="success" align-center>
        <el-step title="信息确认"></el-step>
        <el-step title="完善资料或授权"></el-step>
      </el-steps>
      <el-row class="pay-ali-config-choose" v-show="step===0">
        <el-col :span="10" :offset="2">
          <div class="card " :class="type === 'individual'? 'active': ''" @click="type='individual'">
            <div class="card-head">
              <div>
                <p class="title">已接入支付宝支付</p>
                <span class="desc">已经签约支付宝当面付功能</span>
                <i class="el-icon-circle-check"></i>
              </div>
            </div>
            <div class="card-body" style="padding: 30px;">
              <ul class="detail-wrapper">
                <li>设备支付方式：【支付宝支付】</li>
                <li>提供资质材料：无需提供资质，直接授权【鸿运收银台】</li>
                <li>收款方式：经营者支付宝商户账号</li>
                <li>支持个体户申请、公司申请</li>
              </ul>
              <div class="tags-wrapper">
                <el-tag size="small">个体户经营</el-tag>
                <el-tag size="small">公司经营</el-tag>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="10">
          <div class="card " :class="type !== 'individual'? 'active': ''" @click="type='enterprise'">
            <div class="card-head">
              <div>
                <p class="title">从未接入支付宝</p>
                <span class="desc">已有店铺门头照</span>
                <i class="el-icon-circle-check"></i>
              </div>
            </div>
            <div class="card-body" style="padding: 30px;">
              <ul class="detail-wrapper">
                <li>设备支付方式：【支付宝支付】</li>
                <li>提供资质材料：店铺招牌、营业执照(可选)</li>
                <li>收款方式：经营者支付宝商户账号</li>
                <li>支持个体户申请、公司申请</li>
              </ul>
              <div class="tags-wrapper">
                <el-tag size="small">个体户经营</el-tag>
                <el-tag size="small">公司经营</el-tag>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>

      <ali-apply v-if="step==1 && type !== 'individual'"
                 :name.sync="info.name"
                 :access-token-url="accessTokenUrl" :uuid="info.uuid"></ali-apply>


      <el-form ref="form" :model="info" :rules="rules" label-width="160px" class="item-form" size="small"
               v-if="step==1 && type === 'individual'">
        <el-form-item label="支付名称" prop="name">
          <el-input v-model="info.name" placeholder="请输入支付名称" style="width: 240px"></el-input>
        </el-form-item>
        <el-form-item label="支付授权">
          <div>
            <qrcode :value="accessTokenUrl" :options="{ width: 200 }"></qrcode>
            <p style="margin-left: 16px">请填写上述信息后点击下方【刷新二维码】<br/>并截图给客户(若已经签约,请勿重复签约)</p>
          </div>
        </el-form-item>
      </el-form>


      <div class="step-wrap" :class="step ===1 && type !== 'individual' ? 'padding240': ''">
        <el-button type="primary" @click="step=step+1" v-show="step===0">下一步</el-button>
        <el-button type="primary" @click="step=step-1" v-show="step!==0">上一步</el-button>
        <el-button type="primary" @click="handleQrClick" v-show="step!==0">刷新二维码</el-button>
      </div>
    </div>


  </div>

</template>

<script>

  import AliApply from './apply'
  import CDialog from '@/components/Dialog'
  import {mapGetters} from 'vuex'
  import {payAliUpdate} from "@/api/collection/pay";

  export default {
    name: 'wx-config',
    components: {
      AliApply,
      CDialog
    },
    computed: {
      ...mapGetters(['employee'])
    },
    mounted: function () {
      let host = `https://v2.yuncafei.com`

      let usrUuid = this.employee.uuid
      let uuid = new Date().Format('yyyyMMddhhmmss') + usrUuid
      this.info.uuid = uuid
      this.uri = `${host}/api/v1/collection/ali/audit?uuid=${uuid}`

      this.accessTokenUrl = `二维码无效`

    },
    data: function () {

      return {
        accessTokenUrl: ``,
        appId: `2021001173679595`,
        step: 0,
        type: 'individual',
        showAudit: false,
        info: {
          name: '',
          uuid: '',
        },
        uri: '',
        rules: {
          name: [
            {required: true, message: '请输入公司', trigger: 'blur'},
            {min: 3, message: '长度不少于3个字符', trigger: 'blur'}
          ],
        },
      }
    },
    methods: {
      handleCommit() {
        if (!this.info.name) {
          this.$message.warning('请输入支付名称')
        } else {
          payAliUpdate(this.info).then(res => {
            this.$message.success('添加成功')
            this.$router.back()
          })
        }
      },
      handleQrClick() {
        if (!this.info.name) {
          this.$message.warning('请输入支付名称')
          return
        }
        let vue = this
        let ok = localStorage.getItem('checkAliConfirmInfo')
        let bid = this.employee.enterpriseUuid
        if (ok) {
          this.info.name = this.info.name.replace(`支付宝`, `支付`)
          let uri = encodeURI(this.uri+`&name=${this.info.name}&bid=${bid}`)
          let app = `WEBAPP,MOBILEAPP,TINYAPP`
          vue.accessTokenUrl = `https://openauth.alipay.com/oauth2/appToAppBatchAuth.htm?app_id=${this.appId}&application_type=${app}&redirect_uri=${uri}`
        } else {
          this.$confirm('确保编号和名称都填写后，可直接截图给客户，系统自动完成后续工作', '提示', { type: 'warning' }).then(() => {
            localStorage.setItem('checkAliConfirmInfo', `true`)
            // if (this.info.name.indexOf(`支付宝`) !== -1) {
            //   this.info.name = this.info.name
            // }
            this.info.name = this.info.name.replace(`支付宝`, `支付`)
            let uri = encodeURI(this.uri+`&name=${this.info.name}&bid=${bid}`)
            let app = `WEBAPP,MOBILEAPP,TINYAPP`
            vue.accessTokenUrl = `https://openauth.alipay.com/oauth2/appToAppBatchAuth.htm?app_id=${this.appId}&application_type=${app}&redirect_uri=${uri}`
          }).catch(() => {

          })
        }
        console.log(vue.accessTokenUrl)


      }
    }
  }
</script>

<style lang="scss">
  .pay-ali-config {
    .step {
      margin-bottom: 32px;
      margin-top: 20px;
    }

    &-container {
      padding: 5% 10%;
    }

    &-choose {
      margin-top: 20px;

      .card {
        cursor: pointer;
        margin: 0 8%;
        border: 1px solid;
        border-radius: 4px;
        @include set_border_color($--border-color-light, $--border-color);

        .card-head {
          border-bottom: 1px solid #e9eaec;
          padding: 20px 30px;
          line-height: 1;
          position: relative;
        }

        i {
          display: none;
          position: absolute;
          right: 10px;
          top: 10px;
          color: #fff;
          font-size: 40px;
        }


        .title {
          display: inline-block;
          width: 100%;
          height: 20px;
          line-height: 20px;
          color: #1c2438;
          font-weight: 700;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 16px;
          margin-bottom: 5px;
        }

        .detail-wrapper {
          list-style-type: none;
          counter-reset: sectioncounter;

          li:before {
            content: counter(sectioncounter) "、";
            counter-increment: sectioncounter;
          }

          li {
            padding: 12px 0;
            border-bottom: 1px solid #e9eaec;
          }
        }

        .desc {
          color: #999;
        }

        .tags-wrapper {
          margin-top: $padding;
        }
      }

      .card.active {
        border: none;
        border-color: $-theme-light-1;
        -webkit-box-shadow: 0 1px 6px 1px $-theme-light-5;
        box-shadow: 0 1px 6px 1px $-theme-light-5;

        .card-head:before {
          position: absolute;
          right: 0;
          top: 0;
          content: "";
          display: block;
          width: 0;
          height: 0;
          border: 50px solid $_theme_color;
          border-left-color: transparent;
          border-bottom-color: transparent;
        }

        i {
          display: inline-block;

        }
      }
    }

    .step-wrap {
      margin-top: 40px;
      text-align: center;
    }

    .item-form {
      margin-top: $margin;
      width: 50%;
      margin-left: auto;
      margin-right: auto;
    }

    .padding240 {
      padding-right: 240px;
    }

  }

  body.light {
    .pay-ali-config {
      .el-step__head.is-success {
        color: $_theme_color;
        border-color: $_theme_color;
      }

      .el-step__title.is-success {
        color: $_theme_color;
      }
    }
  }
</style>
